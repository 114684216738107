/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "node_modules/@swimlane/ngx-datatable/index.css";

@import "~@ng-select/ng-select/themes/default.theme.css";

//THEME

body,
ion-text,
ngx-datatable {
    user-select: text;
}

body {
    input {
        color-scheme: light;
    }

    &.dark {
        input {
            color-scheme: dark;
        }
    }
}

// Common CSS
.text-primary {
    color: var(--global-color-primary);
}

.text-secondary {
    color: var(--global-color-secondary);
}

.full-height {
    height: 100%;
}

.circle-button {
    min-height: auto;
    width: 25px;
    height: 25px;
    --border-radius: 50%;
    --padding-start: 6px;
    --padding-end: 6px;
}

.vertical-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.inline-container {
    display: inline-block;

    ion-select.bulk-action {
        min-width: 230px;
        padding-right: 15px;
        min-height: auto;

        &::part(container) {
            margin: 8px 5px;
        }
    }
}

.align-items-center {
    display: flex;
    align-items: center;
}

.strike .clickable-text {
    color: var(--ion-color-danger);
    text-decoration: line-through;
}

.strike .clickable-text:hover {
    text-decoration: none;
}

//Environment Badge
ion-badge.env-badge {
    &.local {
        background: var(--env-badge-local);
    }

    &.dev {
        background: var(--env-badge-dev);
    }

    &.qa {
        background: var(--env-badge-qa);
    }

    &.staging {
        background: var(--env-badge-staging);
    }
}

//Split Pane
ion-split-pane {
    .header-md {
        box-shadow: 4px 1px 2px 2px rgba(0, 0, 0, 0.12) !important;
    }

    //Sidebar
    ion-menu {
        border: 0;

        ion-content {
            --padding-top: 0px !important;
            --padding-start: 0px !important;
            --padding-end: 0px !important;

            .menu-item {
                font-size: 0.9em !important;
                font-weight: 600;
            }

            .submenu-item {
                font-size: 0.9em !important;
                padding-left: 55px;
            }

            //Logo
            .app-header-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .app-logo-container {
                    width: 100%;
                    padding: 8px 0px 4px 0px;
                    text-align: center;
                    z-index: 1;
                    box-shadow: 4px 1px 2px 2px rgba(0, 0, 0, 0.12);
                }

                .app-logo {
                    max-height: 40px;
                }

                .app-title-container {
                    width: 100%;
                    height: 20px;
                    padding: 0px;
                    background: var(--global-accent);
                    color: white;
                    text-align: center;
                }

                .app-title {
                    font-size: 10px;
                    font-weight: 700;
                }

                ion-grid,
                ion-row,
                ion-col {
                    padding: 0px;
                }
            }
        }
    }

    //Staff Profile Component
    .staff-profile {
        font-size: 0.8em;
        padding: 5px 0px 0px 0px;

        .time-tracker-container {
            min-height: 85px;
        }

        .staff-avatar {
            background-color: var(--ion-color-primary-contrast);
            border: 2px solid var(--staff-status-offline);
            border-radius: 50%;
            width: 70px;
            height: 70px;
        }

        .staff-name {
            font-weight: 600;
            margin-bottom: 4px;
        }

        .start-timer-button {
            padding-top: 8px;
        }

        .timer-controls-container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;

            .timer {
                font-weight: 600;
                padding: 6px 16px;
                border-radius: 4px;
                background: var(--ion-color-medium-shade);
                color: var(--global-color-white);
            }

            .stop-timer-button {
                min-height: auto;
                width: 25px;
                height: 25px;
                --border-radius: 50%;
                --padding-start: 6px;
                --padding-end: 6px;
            }
        }

        &.online {
            .staff-avatar {
                border: 2px solid var(--staff-status-online);
            }

            .timer {
                background: var(--staff-status-online, #7f7f7f);
            }

            ion-select.staff-status::part(text) {
                color: var(--staff-status-online);
            }
        }

        &.in_a_meeting {
            .staff-avatar {
                border: 2px solid var(--staff-status-in-a-meeting);
            }

            .timer {
                background: var(--staff-status-in-a-meeting, #7f7f7f);
            }

            ion-select.staff-status::part(text) {
                color: var(--staff-status-in-a-meeting);
            }
        }

        &.busy {
            .staff-avatar {
                border: 2px solid var(--staff-status-busy);
            }

            .timer {
                background: var(--staff-status-busy, #7f7f7f);
            }

            ion-select.staff-status::part(text) {
                color: var(--staff-status-busy);
            }
        }

        &.lunch_break {
            .staff-avatar {
                border: 2px solid var(--staff-status-lunch-break);
            }

            .timer {
                background: var(--staff-status-lunch-break, #7f7f7f);
            }

            ion-select.staff-status::part(text) {
                color: var(--staff-status-lunch-break);
            }
        }

        &.bio_break {
            .staff-avatar {
                border: 2px solid var(--staff-status-bio-break);
            }

            .timer {
                background: var(--staff-status-bio-break, #7f7f7f);
            }

            ion-select.staff-status::part(text) {
                color: var(--staff-status-bio-break);
            }
        }

        &.official_business {
            .staff-avatar {
                border: 2px solid var(--staff-status-official-business);
            }

            .timer {
                background: var(--staff-status-official-business, #7f7f7f);
            }

            ion-select.staff-status::part(text) {
                color: var(--staff-status-official-business);
            }
        }
    }

    .staff-status {
        font-weight: 600;
        min-height: auto;
        padding-top: 4px;

        &::part(container) {
            font-size: 12px;
            margin: 0 auto;
            padding-bottom: 4px;
        }
    }
}

//Form
.form-card {
    border-bottom: 5px solid var(--datatable-card-border);
    border-top: 20px solid var(--datatable-card-border);
}

form {
    ion-input,
    ion-select,
    ion-textarea,
    ion-checkbox {
        margin-bottom: 24px;

        .input-bottom {
            position: absolute;
            bottom: -20px;
        }
    }

    .form-checkbox {
        display: flex;
        vertical-align: middle;
        align-items: center;
        height: 100%;
        padding-left: 12px;

        ion-checkbox {
            margin-bottom: 0;
        }
    }
}

//Alert
ion-alert {
    //header
    .alert-head {
        background: var(--global-accent);
        margin-bottom: 16px;

        .alert-title {
            color: white;
        }

        .alert-sub-title {
            font-size: 0.9em;
            color: white;
        }
    }

    //body
    .alert-message:not(.sc-ion-alert-md:empty),
    .alert-input-group {
        padding: 0 20px !important;
        margin-bottom: 16px;
    }
}

//Datatable
.providers-datatable,
.examinations-datatable {
    .datatable-body-row .datatable-row-center {
        min-height: 50px;
    }
}

.providers-datatable,
.examinations-datatable {
    .datatable-body-row .datatable-body-cell {
        padding: 8px 0px;
    }
}

.examinations-datatable {
    .datatable-body-row .datatable-body-cell .datatable-body-cell-label {
        padding: 0px !important;
    }
}

.datatable {
    border-bottom: 5px solid var(--datatable-card-border);
    border-top: 20px solid var(--datatable-card-border);

    .circle-button {
        position: absolute;
        top: 8px;
    }

    .datatable-row-wrapper:nth-child(odd) {
        .datatable-body-row {
            background: var(--datatable-row-shade);
        }
    }

    .datatable-body-cell:not(.table-action-button-container) {
        .datatable-body-cell-label {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-right: 8px;
        }
    }

    .table-action-button-container {
        padding-right: 8px;
    }

    ion-card-header {
        background: var(--datatable-row-shade);
    }

    .datatable-row-center {
        padding: 0px 10px;
    }

    .table-header-actions-container {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .datatable-footer {
        .datatable-pager {
            .pager {
                li {
                    margin: 0 4px !important;

                    a {
                        text-decoration: none;
                    }

                    &:not(.pages) {
                        a {
                            vertical-align: middle;
                        }
                    }

                    &.disabled,
                    &.active {
                        a {
                            color: var(--ion-color-medium);
                        }
                    }
                }
            }
        }
    }

    &.with-tabs {
        width: 100%;
        border-top: 0;
    
        ion-card-header {
            padding: 0;
        }
    
        ion-segment {
            display: flex;;
            justify-content: start;
            border-bottom: 3px solid  var(--ion-color-primary);
        }
        
        ion-segment-button {
            --background-checked: var(--ion-color-primary);
            --background-hover: var(--ion-color-primary);
            --background-hover-opacity: .1;
            --color-checked: var(--listbox-text-color);
            --indicator-height: 0;
            min-width: 200px;
            max-width: 200px;
        }

        .header-actions {
            background: var(--datatable-row-shade);
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }
}

ion-select {
    &.right-align {
        &::part(container) {
            margin: 0 0 0 auto;
        }
    }
    &.center-align {
        &::part(container) {
            margin: 0 auto;
        }
    }
}

ngx-datatable.module-codes-mapping {
    height: 610px;

    .center-header {
        text-align: center; 
    }

    .datatable-body-cell-label {
        padding: 0px !important;
    }

    .center-cell {
        display: flex;
        justify-content: center; 
        align-items: center; 
    }

    .datatable-header-cell-label, .datatable-body-cell-label {
        padding: 0px 16px;
    }

    .header-background {
        height:50px;
        background: var(--background); 
    }
    
    .datatable-row-wrapper:nth-child(odd) {
        .frozen-column {
            background: var(--datatable-row-light);      
        }
    }

    .datatable-row-wrapper:nth-child(even) {
        .frozen-column {
            background: var(--background);
        }
    }

    .frozen-column::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0; 
        width: 1px; 
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        filter: blur(4px); 
        z-index: -1;
    }
}

ngx-datatable {
    datatable-header-cell {
        color: var(--ion-text-color);
        font-size: 16px;
        font-weight: 700;

        .datatable-header-cell-template-wrap {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    datatable-body-cell {
        font-size: 14px;
        display: flex !important;
        width: 100%;
        align-items: center;
        border-top: 1px solid var(--ion-border-color);
        border-bottom: 1px solid var(--ion-border-color);

        .datatable-body-cell-label {
            width: 100%;
        }

        &.datatable-button-text-container {
            ion-button {
                margin-right: 8px;
            }

            .datatable-body-cell-label {
                display: flex;
                align-items: center;
            }
        }

        .cell-align-center {
            display: flex;
            align-items: center;
        }
    }

    .datatable-row-detail {
        height: auto !important;
    }

    .row-detail-toggle {
        display: flex;
    }

    &.full-width {
        .datatable-row-detail {
            min-width: 500px;
        }
    }

    &.single-selection {
        datatable-body-cell {
            border-top: 0;
            border-bottom: 0;
        }

        .datatable-body-row{
            transition-property: background;
            transition-duration: .3s;

            &:hover {
                background-color: var(--ion-color-primary-tint)!important;
                cursor: pointer;
            }

            &.active {
                background-color: var(--ion-color-primary)!important;
            }
        }
    }
}

//Notification Card
.notification-card {
    box-shadow: none;

    ion-card-content {
        padding: 10px 15px;
    }
}

.progress-bar {
    min-height: 5px;
}

//Date Selection
.date-selection-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
}

.record-status {
    &.pending {
        color: var(--status-pending);
        &::part(text) {
            color: var(--status-pending);
        }
    }

    &.for_approval {
        color: var(--status-for-approval);
        &::part(text) {
            color: var(--status-for-approval);
        }
    }

    &.for_release {
        color: var(--status-for-release);
        &::part(text) {
            color: var(--status-for-release);
        }
    }

    &.sent {
        color: var(--status-approved);
        &::part(text) {
            color: var(--status-approved);
        }
    }
}

.bulk-exam-status {
    min-width: 290px !important;
}

.exam-status {
    white-space: normal;

    &.pending {
        color: var(--status-pending);
        &::part(text) {
            color: var(--status-pending);
        }
    }

    &.completed {
        color: var(--ion-color-success);
        &::part(text) {
            color: var(--ion-color-success);
        }
    }

    &.cancelled {
        color: var(--ion-color-warning);
        &::part(text) {
            color: var(--ion-color-warning);
        }
    }

    &.cancelled_imo,
    &.cancelled_provider,
    &.no_show,
    &.late_cancellation,
    &.imo_not_completed,
    &.imo_not_in_timesheet {
        color: var(--ion-color-danger);
        &::part(text) {
            color: var(--ion-color-danger);
        }
    }

    &.rescheduled {
        color: var(--ion-color-primary);
        &::part(text) {
            color: var(--ion-color-primary);
        }
    }
}

.exam-approved-status {
    white-space: normal;
    --padding-start: 0;
    --padding-end: 0;
    --min-height: unset;
    --height: auto;
    --border-radius: 16px;
    --box-shadow: none;
    --font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    min-height: 28px;

    &::part(placeholder) {
        color: var(--ion-color-light, #fff);
    }

    &.pending {
        --background: var(--select-chip-bckgrnd-default);
        color: var(--status-pending);

        &::part(text) {
            color: var(--status-pending);
        }
    }

    &.approved {
        --background: var(--select-chip-bckgrnd-success);
        color: var(--ion-color-success);

        &::part(text) {
            color: var(--ion-color-success);
        }
    }
}

//STATUS LOGS
ion-text.status.online {
    color: var(--staff-status-online);
}

ion-text.status.in_a_meeting {
    color: var(--staff-status-in-a-meeting);
}

ion-text.status.busy {
    color: var(--staff-status-busy);
}

ion-text.status.bio_break {
    color: var(--staff-status-bio-break);
}

ion-text.status.lunch_break {
    color: var(--staff-status-lunch-break);
}

ion-text.status.official_business {
    color: var(--staff-status-official-business);
}

// Date Range Picker
.daily-dates-container,
.summary-dates-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.summary-dates-container {
    margin-bottom: 2px;
}

// Calculator
app-provider-examination-view,
app-tier-calculator,
app-provider-examination-modal {
    .dual-list {
        column-gap: 16px !important;
        margin: 0 !important;

        .button-bar {
            flex-wrap: wrap;
            row-gap: 10px;
        }
    }

    .selections-container {
        padding: 28px 16px 20px !important;
    }

    .datatable {
        border: none;
    }

    .timesheet-label {
        font-size: 18px !important;
        text-align: right;
    }

    .summary-label {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &.border-top {
            margin-top: 5px;
            padding-top: 5px;
            border-top: 1px solid var(--ngselect-border-color);
        }
    }

    .imo-only-container {
        border-bottom: 1px solid var(--ngselect-border-color);
        padding-bottom: 12px;
        margin: 5px 0 15px;
    }

    .add-ons-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .dbq-options {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        ion-toggle {
            width: fit-content;
        }

        .dbq-count {
            margin: 16px 0;
        }
    }

    ion-list {
        padding: 0 !important;
    }

    ion-item {
        --inner-border-width: 0;
    }
}

ion-toolbar.small-ion-toolbar {
    --min-height: 34px;
}

.or-seperator {
    padding: 8px 0;
}

ion-card.dbq-selector {
    margin: 16px 0;
    box-shadow: none;
}

// Alert Error Box
.alert-error-box {
    --max-width: 450px;
}

// Ng Select Box
app-select-box.bot-margin .ng-select {
    margin-bottom: 24px;
}

.ng-select {
    .ng-select-container {
        background: transparent !important;
        border-color: var(--ngselect-border-color);
        border-radius: var(--ngselect-border-radius);
        padding: 0 6px 0 16px;
        min-height: 56px;
        transition: 0.1s;

        &:after {
            border-bottom: thin solid var(--color);
        }

        &:hover,
        &:focus {
            border-color: var(--ion-color-step-750);
        }

        &:hover:after {
            border-bottom: thin solid var(--ion-color-step-750);
        }

        .ng-value-container {
            padding: 0;
            .ng-placeholder,
            .ng-value {
                color: var(--color);
            }

            .ng-placeholder {
                font-size: 0.875rem;
            }
        }

        .ng-clear-wrapper .ng-clear {
            color: var(--color);
        }

        .ng-arrow-wrapper .ng-arrow {
            border-color: var(--color) transparent transparent;
            border-width: 6px 5.5px 0;
            transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1),
                -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
        }

        .ng-clear-wrapper {
            justify-content: center;
            align-items: center;
            display: flex;
        }
    }

    &.ng-select-disabled {
        pointer-events: none;
        opacity: 0.4;
    }

    &.ng-select-searchable .ng-select-container .ng-value-container .ng-input {
        color: var(--color);
        padding: 0 0 0 14px;
        height: 100%;
        margin-top: -5px;
        cursor: pointer;

        input {
            height: inherit;
            cursor: pointer;
        }
    }

    &.ng-invalid.ng-touched .ng-select-container {
        border-color: var(--ion-color-danger);

        .ng-value-container .ng-placeholder,
        .ng-value-container .ng-value {
            color: var(--ion-color-danger);
        }

        .ng-arrow-wrapper .ng-arrow {
            border-color: var(--ion-color-danger) transparent transparent;
            border-width: 6px 5.5px 0;
        }
    }

    &.ng-select-opened .ng-select-container {
        border-width: 2px;
        border-color: var(--ion-color-success);

        .ng-arrow-wrapper .ng-arrow {
            top: 0;
            transform: rotate(180deg);
        }
    }
}

ng-dropdown-panel {
    background-color: var(--ngselect-dropdown-panel-bckgrnd) !important;
    border: none !important;
    margin-top: 0 !important;

    .ng-dropdown-panel-items .ng-option {
        background-color: var(--ngselect-dropdown-panel-bckgrnd) !important;
        font-size: 1em;
        color: var(--ion-text-color) !important;
        padding: 15px 16px !important;

        &.ng-option-selected {
            background: var(--ngselect-option-bckgrnd-selected) !important;

            &:hover {
                background: var(--ngselect-option-bckgrnd-focused) !important;
            }

            .ng-option-label {
                font-weight: normal !important;
            }
        }

        &:hover {
            background: var(--ngselect-option-bckgrnd-hover) !important;
        }
    }
}

// Staff View Page
.staff-header {
    background: var(--ion-background-color);
    width: 100%;
    border-radius: 4px;
    margin: 15px;
    padding: 0;
}

.profile-cover-bckgrnd {
    background: url("/assets/login-bg.png") no-repeat center/cover fixed;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 100%;
    height: 200px;
}

.profile-image {
    width: 100px;
    height: 100px;
    min-width: 100px;
    margin: -10px 0 0;
    border: 3px solid var(--staff-status-offline);
    border-radius: 50%;
    overflow: hidden;

    img {
        background-color: var(--ion-color-primary-contrast);
        width: 100%;
        height: auto;
    }

    &.online {
        border: 3px solid var(--staff-status-online);
    }

    &.in_a_meeting {
        border: 3px solid var(--staff-status-in-a-meeting);
    }

    &.busy {
        border: 3px solid var(--staff-status-busy);
    }

    &.lunch_break {
        border: 3px solid var(--staff-status-lunch-break);
    }

    &.bio_break {
        border: 3px solid var(--staff-status-bio-break);
    }

    &.official_business {
        border: 3px solid var(--staff-status-official-business);
    }
}

app-provider-examination-view,
app-staff-view,
app-my-account-profile {
    .staff-status {
        background-color: var(--staff-status-offline);
        font-weight: 400;
        color: var(--global-color-white);
        padding: 4px 10px;
        margin: 0 0 0 10px;
    
        &.online {
            background-color: var(--staff-status-online);
        }
    
        &.in_a_meeting {
            background-color: var(--staff-status-in-a-meeting);
        }
    
        &.busy {
            background-color: var(--staff-status-busy);
        }
    
        &.lunch_break {
            background-color: var(--staff-status-lunch-break);
        }
    
        &.bio_break {
            background-color: var(--staff-status-bio-break);
        }
    
        &.official_business {
            background-color: var(--staff-status-official-business);
        }
    }

    .top-content {
        padding: 0;
    }
    
    .bot-content {
        display: flex;
        width: 100%;
        margin: -58px 0 0 0;
        padding: 0 24px;
    
        ion-row {
            display: flex;
        }
    
        .inner-content {
            margin: 0 0 0 1.5rem;
            padding: 0;
        }
    
        ion-segment-button {
            min-width: 50px;
        }
    
        ion-row {
            width: 100%;
        }
    }
    
    .staff-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        h2 {
            color: var(--global-color-white);
        }
    }
    
    .staff-tabs {
        ion-segment {
            justify-content: flex-start;
        }
    }
    
    .staff-info-tab {
        width: 100%;
        margin: 15px;
    }
    
    .row-detail {
        display: flex;
        flex-direction: column;
        margin: 0 0 20px;
    
        ion-label {
            color: var(--ion-color-dark);
            margin: 0 0 4px;
        }
    
        ion-chip {
            width: fit-content;
            margin: 0;
        }
    }
}

// Staff Photo Modal
.staff-photo-modal {
    .image-cropper-container {
        background: var(--ion-color-primary-contrast);
        width: 370px;
        height: 370px;

        &.cropper-hidden {
            background: url("/assets/avatar.svg") no-repeat center / cover;
        }
    }

    image-cropper {
        background: aliceblue;
        align-items: center;
        justify-content: center;
        transition: all .3s;

        .ngx-ic-source-image {
            background-color: transparent;
        }
    }

    .helper-text {
        font-size: 14px;
        font-style: italic;
        margin: 10px 0;
    }
}

.cropper-container {
    display: flex;
    column-gap: 16px;
    margin: 0 0 20px;

    .cropped-image {
        background-color: var(--ion-color-primary-contrast);
        display: block;
        width: 180px;
        height: 180px;
        border: 3px solid var(--staff-status-offline);
        border-radius: 50%;
    }
}

// Speciality Form
.addon-bonus {
    padding-bottom: 24px;
}

.addon-delete {
    padding-top: 6px;
}

// Staff Masterlist
.welcome-email-info {
    position: relative !important;
    top: unset !important;
}

// Speciality Form
.case-delete {
    padding-top: 11px;
}

.case-container {
    margin-bottom: 24px;
}
 
hr.line-seperator {
    border-bottom: 1px solid var(--ion-color-light-tint);
    margin: 10px 0 20px;
    width: 100%;
}

.provider-exam-modal{
    --width: 960px;
    --height: 860px;

    form {
        display: inline;
    }

    .calculator-text-status{
        min-height: 120px;
    }
}

app-calculate-exam {
    .calculator-text-status{
        position: relative;
        justify-content: center;
        align-items: center;
        height: 100%;
        min-height: 520px;
        margin: 0;
    }
}

.exam-remarks {
    white-space: normal;
}

.hidden-input {
    display: none !important;
}

// Provider Payroll
.provider-payroll-header {
    background: var(--provider-payroll-header-background);
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    color: var(--listbox-text-color);

    .icon-box {
        width: 64px;
        height: 64px;
        padding: 6px 0;

        ion-icon {
            width: 100%;
            height: 100%;
        }
    }

    h2,
    h5 {
        margin: 0;
    }

    .provider-info {
        flex-grow: 1;
        margin-left: 8px;
    }

    .provider-payroll-date {
        display: flex;
        flex-direction: column;
    }

    .provider-payroll-date-label {
        font-size: 16px;
    }
}

.provider-payroll-content {
    .filters-box {
        padding: 0 24px 16px;
    }

    .approval-info {
        display: flex;
        flex-direction: column;
    }
}

.provider-payroll-adjustment-modal {
    --max-height: 520px;

    app-select-box {
        width: 100%;
    }
}

.provider-payroll-scribe-modal {
    --max-height: 560px;
}

.no-data-message {
    width: 100%;
    padding: 15px 30px;

    h2 {
        line-height: 1.5;
        margin: 0;
    }
}

.provider-payroll-signin-sheet-modal {
    --max-height: 320px;
}

.provider-payroll-signin-sheet-list-modal {
    .uploaded-info {
        display: flex;
        align-items: center;
        column-gap: 8px;
        margin: 6px 0 0;
    }
}

.signin-sheet-uploaded-info {
    display: flex;
    flex-direction: column;
}

.delete-signin-sheet {
    padding: 10px 0 0;
}

ion-segment.small-icon {
    ion-icon {
        font-size: 18px;
    }
}

.hide-checkbox input[type="checkbox"] {
    display: none;
}

.row-cols-equal-height {
    align-items: stretch;

    ion-col {
        display: flex;
        flex-direction: column;
    }

    ion-card {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    ngx-datatable {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        overflow: auto;
    }
}

app-provider-examination-view {
    .examination-view-status {
        min-height: calc(100vh - 56px);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    .edit-exam-info {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .equal-height-tab-contents {
        min-height: 420px;
        max-height: 420px;
    }

    .payroll-examinations-table,
    .exam-adjustments-table {
        min-height: 240px;
        max-height: 240px;
    }

    .payroll-exam-row,
    .exam-adjustment-row {
        position: relative;

        .exam-status {
            position: absolute;
            top: 0;
            left: -8px;
            width: 4px;
            height: 100%;
            border-radius: 0.25rem;

            &.pending {
                background: var(--status-pending);
                &::part(text) {
                    color: var(--status-pending);
                }
            }

            &.completed {
                background: var(--ion-color-success);
                &::part(text) {
                    color: var(--ion-color-success);
                }
            }

            &.cancelled {
                background: var(--ion-color-warning);
                &::part(text) {
                    color: var(--ion-color-warning);
                }
            }

            &.cancelled_imo,
            &.cancelled_provider,
            &.no_show,
            &.late_cancellation {
                background: var(--ion-color-danger);
                &::part(text) {
                    color: var(--ion-color-danger);
                }
            }

            &.rescheduled {
                background: var(--ion-color-primary);
                &::part(text) {
                    color: var(--ion-color-primary);
                }
            }
        }
    }

    .approved-info {
        display: flex;
        align-items: center;
        column-gap: 5px;
    }

    ion-chip {
        br {
            display: contents;
        }

        &.small {
            font-size: 12px;
            line-height: 1;
            padding: 4px 6px;
            margin: 0 8px 4px 0;
            border-radius: 2px;
            min-height: auto;
        }
    }

    app-provider-examinations-status {
        &.small {
            ion-chip {
                font-size: 12px;
                line-height: 1;
                padding: 4px 6px;
                margin: 0 8px 4px;
                border-radius: 2px;
                min-height: auto;
            }
        }
    }
}

.exam-logs {
    ion-card-content {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .logs-data-row {
        display: flex;
        column-gap: 20px;
    }

    .logs-data {
        color: var(--ion-color-dark);

        &.date {
            border: 1px solid var(--datatable-calendar-date);
            border-radius: 4px;
            width: 50px;
            overflow: hidden;
            text-align: center;
            display: flex;
            flex-direction: column;
            pointer-events: none;
        }

        &.info {
            display: flex;
            flex-direction: column;

            ion-text {
                font-size: 0.83rem;
                line-height: 1.3;
            }
        }

        &.data {
            display: block;
        }

        .month {
            background-color: var(--datatable-calendar-date);
            font-size: 0.83rem;
            font-weight: 600;
            line-height: 1.2;
            color: var(--text-color-white);
        }

        .day {
            font-size: 1.728rem;
            font-weight: 700;
            line-height: 1.2;
        }

        .year {
            background-color: var(--staff-status-offline);
            line-height: 1;
            color: var(--text-color-white);
        }
    }

    .search-logs-input {
        input {
            font-size: 0.9rem;
            max-height: 28px;
            width: 200px;
            padding-inline-start: 28px;
            padding-inline-end: 28px;
        }

        .searchbar-search-icon {
            top: 6px;
            inset-inline-start: 6px;
            width: 16px;
            height: 16px;
        }
    }
}

.exam-log-modal {
    ion-row {
        flex-direction: column;
    }

    .row-detail {
        display: flex;
        flex-direction: column;
        margin: 0 0 20px;

        ion-label {
            margin: 0 0 4px;
        }
    }
}

.provider-individual {
    &.file-link-container {
        display: flex;
        align-items: center;

        .file-link {
            padding-right: 6px;
        }

        ion-button {
            position: relative;
            top: unset;
            padding: 0;
        }
    }
}

.json-output {
    background: var(--datatable-row-shade);
    font-family: 'Courier New', Courier, monospace;
    padding: 8px;
    margin: 0;
    border-radius: 4px;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-x: auto;
}

.view-exam-btn {
    --padding-start: 0;
    --padding-end: 0;
}

.payroll-info-table {
    .datatable-row-center {
        align-items: end;
    }
}

.sub-table {
    width: 100%;
    table-layout: fixed;

    &.header {
        border: 1px solid var(--ion-color-medium);
    }

    thead tr {
        display: flex;
        width: 100%;
    }

    th {
        flex: 1;
        text-align: center;
    }

    ion-checkbox {
        padding-top: 6px;
    }
}

// Fade Animations
.fade-in {
	display: none;
	opacity: 0;
    animation: animation-block 0.3s ease-in-out;

    &.visible {
		display: block;
		opacity: 1;
    }
}

.fade-in-flex {
    display: flex;
    opacity: 1;
    animation: animation-flex-hide 0.3s ease-in-out;

    &.hidden {
        display: none;
        opacity: 0;
    }
}

.hide-flex {
    display: none;
    opacity: 0;
    animation: animation-show-flex 0.3s ease-in-out;
    
    &.visible {
		display: flex;
        opacity: 1;
    }
}

@keyframes animation-block {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes animation-flex-hide {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: flex;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes animation-show-flex {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: flex;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

//ADMIN-1385
ion-button.in-toolbar {
    color: var(--global-color-white);
}